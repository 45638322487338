.logo {
  font-size: 16px;
  text-align: center;
}

.logoImg {
  height: 70px;
  margin-top: 5px;
  background-size: 100% 100%;
}

.mini-logo {
  text-align: center;
}

.mini-logoImg {
  height: 35px;
  margin: 5px;
  background-size: 100% 100%;
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: unset;
}

@primary-color: #1890ff;