
.site-layout .site-layout-background {
  background: #fff;
}

.action {
  cursor: pointer;
  align-items: center;

  &:hover {
    opacity: .7;
  }
}


.userName {
  padding: 5px;
}

.adminLayout a {
  color: #fff;
}

.adminLayout a :hover {
  color: #1890ff;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: white;
}
.ant-menu-item-selected a {
  color: black!important;
}

.ant-menu {
  transition: none;
}

.ant-menu-submenu-title .anticon + span {
  transition: none;
}

.ant-menu-item, .ant-menu-submenu-title, .ant-menu {
  transition: none;
}

@primary-color: #1890ff;